import { IonTabBar } from '@ionic/react';
import type { ComponentProps } from 'react';
import React from 'react';

type TabBarTypes = ComponentProps<typeof IonTabBar>;

interface TabBarProps extends TabBarTypes {
  slot: TabBarTypes['slot'];
  children: React.ReactNode;
}

const TabBar: React.FC<TabBarProps> = ({ children, slot = 'bottom' }) => {
  return (
    <IonTabBar slot={slot} style={{ paddingTop: '8px', paddingBottom: '24px' }}>
      {children}
    </IonTabBar>
  );
};

export default TabBar;
