import { IonPage, IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';

import ToolList from './ToolList';
import { NetworkInterceptor } from '../../components/NetworkInterceptors/NetworkInterceptor';
import { matchUuid } from '../../constants/matchUuid';
import ProjectCreate from '../Projects/ProjectCreate/ProjectCreate';
import PreparationForm from '../WorkPreparation/PreparationForm';

const ToolsPage: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <NetworkInterceptor />
        <Route path={'/tools'} component={ToolList} exact />
        <Route path={`/tools/:uuid(${matchUuid})`} component={ToolList} />
        <Route path={`/tools/create-project`} component={ProjectCreate} />
        <Route path={`/tools/work-preparation`} component={PreparationForm} exact />
        <Route path={`/tools/work-preparation/:uuid(${matchUuid})`} component={PreparationForm} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ToolsPage;
