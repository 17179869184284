import { IonContent, IonIcon, IonLabel, IonPage, IonTabButton } from '@ionic/react';
import { appsSharp, chevronBack, notificationsSharp, personCircleOutline, podiumSharp } from 'ionicons/icons';
import React from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';

import appStyles from '../App.module.scss';
import DesktopWrapper from '../components/DesktopWrapper';
import HamburgerMenu from '../components/Menu/HamburgerMenu';
import PageMenuHeader from '../components/PageMenuHeader/PageMenuHeader';
import BigUp from '../components/UI';
import type { ColourVariables } from '../components/UI/variables';
import { useAppSelector } from '../hooks';

type Header = {
  hasHeader: boolean;
  component?: React.FC;
};

interface AppLayoutProps {
  scroll?: boolean;
  headerTitle?: string;
  children: React.ReactNode;
  header?: Header;
  tabs?: boolean;
  contentColour?: ColourVariables;
  canGoBack?: boolean;
  goBackTo?: string;
  content_id?: string;
  menu_id?: string;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  canGoBack = false,
  children,
  content_id,
  contentColour = 'var(--ion-color-light)',
  goBackTo = '/tools',
  header = { hasHeader: true },
  headerTitle,
  menu_id,
  scroll = false,
  tabs
}) => {
  const history = useHistory();
  const location = useLocation();
  const project = useAppSelector(state => state.project.selectedProject);

  const checkProjectId = project?.id ? `/${project.id}` : '';

  const tabButtons = [
    { tab: `/tools${checkProjectId}`, icon: appsSharp, label: 'Tools', hasAccess: true },
    { tab: `/projects${checkProjectId}`, icon: podiumSharp, label: 'Projects', hasAccess: true },
    { tab: `/notice-board${checkProjectId}`, icon: notificationsSharp, label: 'Notice Board', hasAccess: true },
    { tab: `/profile${checkProjectId}`, icon: personCircleOutline, label: 'Profile', hasAccess: true }
  ];

  const activeTab = (tab: string) => matchPath(location.pathname, { path: tab, exact: false }) ? 'primary' : 'medium';

  return (
    <React.Fragment>
      <HamburgerMenu />
      <IonPage id={'main-content'}>
        {header.hasHeader
          ? (
            <PageMenuHeader />
          )
          : (
            <BigUp.Header
              ionTitle={{
                label: headerTitle || '',
              }}
              buttons={{
                start: {
                  show: true,
                  icon: {
                    color: 'primary',
                    icon: chevronBack,
                  },
                  onClick: () => history.go(-1),
                },
                end: {
                  show: false,
                }
              }}
            />
          )
        }
        <IonContent
          style={{ '--background': contentColour, hyphens: 'var(--ion-hyphen)' }}
          id={appStyles['fade-in-page']}
          className={appStyles[scroll ? 'has-scroll' : 'remove-scroll']}
          fullscreen
        >
          <DesktopWrapper alignment={'center'} width={'500px'}>
            {children}
          </DesktopWrapper>
        </IonContent>
        <BigUp.Tabs.TabBar slot="bottom">
          {tabButtons.map((tab, index) => (
            tab.hasAccess && (
              <IonTabButton key={index} tab={tab.tab} href={tab.tab}>
                <IonIcon icon={tab.icon} size='large' color={activeTab(tab.tab)} />
                <IonLabel className='ion-no-margin'>{tab.label}</IonLabel>
              </IonTabButton>
            )
          ))}
        </BigUp.Tabs.TabBar>
      </IonPage>
    </React.Fragment>
  );
};

export default AppLayout;
