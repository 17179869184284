import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRow } from '@ionic/react';
import { t } from 'i18next';
import { flagOutline, logOutOutline } from 'ionicons/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import AppVersion from '../../../components/AppVersion';
import Avatar from '../../../components/Avatar';
import { MENU_LIST } from '../../../components/PageMenuHeader/navLinks';
import BigUp from '../../../components/UI';
import BankID from '../../../components/UI/BankID/BankID';
import LanguageSelect from '../../../components/UI/Select/LanguageSelect';
import { useAppSelector } from '../../../hooks';
import useLogout from '../../../hooks/useLogout';
import useModal from '../../../hooks/useModal';
import i18n from '../../../i18n';
import modalStyle from '../../../pages/Onboarding/Components/containers/styles/ModalContainers.module.scss';
import PersonalOverview from '../../Onboarding/Components/PersonalOverview/PersonalOverview';
import styles from '../Profile/Profile.module.scss';

const Profile: React.FC = () => {
  const clearUserDetails = useLogout();
  const toggleOnboardingModal = useModal();
  const user = useAppSelector(state => state.authentication.user);

  return (
    <>
      <IonGrid className='ion-padding'>
        <IonRow className='ion-justify-content-center ion-align-items-center'>
          <IonCol size='4'>
            <Avatar.AvatarImage
              onClick={toggleOnboardingModal.openModal}
              avatarSource='userImage'
              style={{ width: 100, height: 100, }}
            />
          </IonCol>
          <IonCol size='8'>
            <IonRow className='ion-justify-content-center ion-align-items-center'>
              <IonCol>
                <IonItem className='ion-no-padding' lines='none'>
                  <IonLabel className='ion-no-margin'>
                    <h2 style={{ fontWeight: 600 }}>{t('Name')}:</h2>
                    <p style={{ fontSize: 16 }}>{`${user?.first_name} ${user?.last_name}`}</p>
                  </IonLabel>
                </IonItem>
                <IonItem className='ion-no-padding' lines='none'>
                  <IonLabel className='ion-no-margin'>
                    <h2 style={{ fontWeight: 600 }}>{t('Email')}:</h2>
                    <p style={{ fontSize: 16 }}>{`${user?.email}`}</p>
                  </IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonList>
        {MENU_LIST.map(item => (
          <IonItem key={item.linkText} lines='full'>
            <IonIcon icon={item.itemIcon} className='ion-margin-end'></IonIcon>
            <IonLabel className='ion-no-margin'>
              <Link to={`${item.directTo}`} style={{ textDecoration: 'none', color: 'var(--ion-color-dark)' }}>
                {item.linkText}
              </Link>
            </IonLabel>
          </IonItem>
        ))}
        <IonItem style={{ '--inner-padding-end': '8px' }} lines='full'>
          <IonIcon icon={flagOutline} className='ion-margin-end' />
          <LanguageSelect />
        </IonItem>
        {/**
             * TODO: Comment back out once we are ready to start working on darkmode (functionality works).
            <IonItem style={{ '--inner-padding-end': '8px' }} lines='full'>
              <IonIcon icon={contrastOutline} className='ion-margin-end' />
              <IonLabel className='ion-no-margin' color={'dark'}>{t('Dark mode')}</IonLabel>
              <DarkmodeToggle />
            </IonItem>
            */}
        <IonItem lines='full' button onClick={clearUserDetails}>
          <IonIcon icon={logOutOutline} className='ion-margin-end' />
          <IonLabel className='ion-no-margin' color={'dark'}>{t('Logout')}</IonLabel>
        </IonItem>
      </IonList>
      {user?.ssn
        ? (
          <IonGrid>
            <IonRow className='ion-align-items-center ion-justify-content-center'>
              <BigUp.Label.Regular label={i18n.t('Authenticated with ')} style={{ fontSize: 18 }} /><BankID.BankIdIcon color='black' width={60} />
            </IonRow>
          </IonGrid>
        )
        : <BigUp.Buttons.BankIdAuthenticate />
      }
      <div className={styles['app-version-container']}>
        <h2>{t('BigUp')}</h2>
        <AppVersion />
      </div>
      <IonModal
        isOpen={toggleOnboardingModal.isModalOpen}
        className={modalStyle['personaloverview-modal']}
        onIonModalDidDismiss={toggleOnboardingModal.closeModal}
      >
        <PersonalOverview togglePersonalOverview={toggleOnboardingModal.toggleModal} />
      </IonModal >
    </>
  );
};

export default Profile;
