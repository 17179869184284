import type { RouteInfo } from '@ionic/react';

import i18n from '../../../i18n';

export type Routes = 'risk-assessment' | 'equipment' | 'preparation' | 'preparation_history' | 'preparations' | 'follow-up';

const routeTitle: Map<RouteInfo<Routes>['pathname'], string> = new Map([
  ['risk-assessment', i18n.t('Risk Assessment')],
  ['equipment', i18n.t('Equipment')],
  ['preparation', i18n.t('Preparation')],
  ['preparation_history', i18n.t('Preparation History')],
  ['preparations', i18n.t('Preparations')],
  ['follow-up', i18n.t('Follow Up')]
]);

export default routeTitle;
