import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';

import Profile from './Profile';
import SettingsPage from './Settings';
import { NetworkInterceptor } from '../../components/NetworkInterceptors/NetworkInterceptor';
import { matchUuid } from '../../constants/matchUuid';
import AppLayout from '../../layout/AppLayout';

const ProfilePage: React.FC = () => {
  return (

    <IonRouterOutlet>
      <NetworkInterceptor />
      <Route path={`/profile/:uuid(${matchUuid})`} render={({ match }: any) => (
        <AppLayout scroll>
          <Profile />
        </AppLayout>
      )} />
      <Route path={`/profile/settings`} render={({ match }: any) => (
        <AppLayout scroll>
          <SettingsPage />
        </AppLayout>
      )} />
      <Route path={'/profile'} exact render={({ match }: any) => (
        <AppLayout scroll>
          <Profile />
        </AppLayout>
      )} />
    </IonRouterOutlet>

  );
};

export default ProfilePage;
