import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow, useIonRouter } from '@ionic/react';
import { t } from 'i18next';
import { add, calculatorOutline, hammerOutline, statsChartOutline } from 'ionicons/icons';
import React from 'react';
import { useParams } from 'react-router';

import ProjectSelector from '../../../components/ProjectSelector';
import { BigUp } from '../../../components/UI';
import { useAppSelector } from '../../../hooks';
import usePermissionHandler from '../../../hooks/usePermissions';
import AppLayout from '../../../layout/AppLayout';

interface ListPageProps {
  title?: string | undefined;
  description: string;
}

const ToolList: React.FC<ListPageProps> = ({ description }) => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { checkPermission } = usePermissionHandler();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const router = useIonRouter();

  const handleRedirect = (route: string, param: 'control-of-execution' | 'project-information') => {
    if (isDesktop) {
      router.push(`/${route}/${uuid}/${param}`);
    } else {
      router.push(`/${route}/${uuid}`);
    }
  };
  const handleWorkPreparationRedirect = () => {
    if (uuid) {
      router.push(`/tools/work-preparation/${uuid}`);
    } else {
      router.push('/tools/work-preparation');
    }
  };
  return (
    <AppLayout>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%' }}>
        <div className={'ion-padding'}>
          <IonGrid className='ion-no-margin'>
            <IonRow className={'ion-no-padding ion-no-margin ion-align-items-center'} >
              <IonCol size={'4'} className={'ion-no-padding'}>
                <BigUp.Title label={t('Tools')} className={'ion-no-margin ion-no-padding'} />
              </IonCol>
              <IonCol size='8'>
                {isDesktop && (
                  <ProjectSelector currentTab={'tools'} color='var(--ion-color-dark)' />
                )}
              </IonCol>
              <IonCol size={'12'} className={'ion-no-padding'}>
                <IonList mode='ios'>
                  <IonItem button className='ion-no-padding' routerLink='/economy-tools/precalculations'>
                    <IonIcon icon={calculatorOutline} slot="start"></IonIcon>
                    <IonLabel>{t('My precalculations')}</IonLabel>
                  </IonItem>
                  {/*
                  // Keep until work preparation is implemented
                    <IonItem button className='ion-no-padding' onClick={() => handleWorkPreparationRedirect()}>
                      <IonIcon icon={clipboardOutline} slot="start"></IonIcon>
                      <IonLabel>{t('Work preparations')}</IonLabel>
                    </IonItem>
                  */}
                  <IonItem button className='ion-no-padding' onClick={() => router.push('/tools/create-project')}>
                    <IonIcon icon={add} slot="start"></IonIcon>
                    <IonLabel>{t('Create a new project')}</IonLabel>
                  </IonItem>
                  {uuid && (
                    <>
                      {checkPermission('project_tools_access', 'read') && (
                        <IonItem button className='ion-no-padding'
                          onClick={() => handleRedirect('project-tools', 'control-of-execution')}>
                          <IonIcon icon={hammerOutline} slot="start"></IonIcon>
                          <IonLabel>{t('Project tools')}</IonLabel>
                        </IonItem>
                      )}
                      {checkPermission('economy_tools_access', 'read') && (
                        <IonItem button className='ion-no-padding'
                          onClick={() => handleRedirect('economy-tools', 'project-information')}
                        >
                          <IonIcon icon={statsChartOutline} slot="start"></IonIcon>
                          <IonLabel>{t('Economy tools')}</IonLabel>
                        </IonItem>
                      )}

                    </>
                  )}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </AppLayout>
  );
};

export default ToolList;
