import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { FormProvider, useForm } from 'react-hook-form';

import Section from './Sections';
import BigUp from '../../../components/UI';
import i18n from '../../../i18n';
import AppLayout from '../../../layout/AppLayout';

export interface WorkPreparation {
  company: string;
  contact_person: string;
  project: string;
  project_id: string;
  activity: string;
  work_activities: string;
  description: string;
  risks: string[];
  equipment: string[];
  follow_up: string[];
}

const defaultValues: WorkPreparation = {
  company: '',
  contact_person: '',
  project: '',
  project_id: '',
  activity: '',
  work_activities: '',
  description: '',
  risks: [],
  equipment: [],
  follow_up: [],
};

const PreparationForm: React.FC = () => {
  const methods = useForm<WorkPreparation>({
    defaultValues,
  });

  const onSubmit = (data: WorkPreparation) => {
    console.log(data);
  };

  return (
    <AppLayout
      header={{ hasHeader: false }}
      tabs={false}
      scroll={true}
      contentColour='var(--ion-color-light-shade)'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Section.Project />
          <Section.Description />
          <Section.Risks />
          <Section.Equipment />
          <Section.FollowUp />
          <IonGrid>
            <IonRow className='ion-justify-content-center'>
              <IonCol size={'6'}>
                <BigUp.Buttons.Regular
                  expand='block'
                  size='large'
                  shape='round'
                  title={i18n.t('Sign')}
                  type='submit'
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </FormProvider>
    </AppLayout>
  );
};

export default PreparationForm;
