import { IonBackButton, IonButtons, IonContent, IonHeader, IonNav, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, matchPath, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import Examples from './Examples';
import ProtectedRoute from './ProtectedRoute';
import { WrappedRoute } from './WrappedRoute';
import { NetworkInterceptor } from '../components/NetworkInterceptors/NetworkInterceptor';
import { matchUuid } from '../constants/matchUuid';
import { onboardingPath, superOptionalOnboardingRoute } from '../constants/onboarding';
import { useAppSelector } from '../hooks';
import AppLayout from '../layout/AppLayout';
import ClientAccountPage from '../pages/ClientAccount';
import DocumentsListPage from '../pages/Document/DocumentsListPage';
import UploadDocumentPage from '../pages/Document/UploadDocumentPage';
import EconomyPage from '../pages/Economy/EconomyPage';
import HomePage from '../pages/Home';
import IntroductionPage from '../pages/Introduction';
import AcceptInvitationPage from '../pages/Invitation/AcceptInvitationPage';
import Login from '../pages/Login';
import ConsumePasswordResetPage from '../pages/Login/ConsumePasswordResetPage';
import PasswordResetPage from '../pages/Login/PasswordResetPage';
import Onboarding from '../pages/Onboarding';
import PageNotFound from '../pages/PageNotFound';
import ProjectReportsPage from '../pages/ProjectReports';
import ProjectsPage from '../pages/Projects';
import ProjectCreate from '../pages/Projects/ProjectCreate/ProjectCreate';
import ProjectList from '../pages/Projects/ProjectList';
import ProjectPage from '../pages/Projects/ProjectPage';
import ObservationReport from '../pages/Projects/ReportsPage/ObvervationReports/ObservationReport';
import Registration from '../pages/Registration';
import IncidentReport from '../pages/Reports/IncidentReport';
import ClickupForm from '../pages/Support/ClickupForm';
import Support from '../pages/Support/Support';
import ToolList from '../pages/Tools/ToolList';
import UserSettings from '../pages/User/UserSettings';
import UserPhoto from '../pages/UserPhoto/index';

enum Level {
  danger = 'danger',
  warning = 'warning',
  info = 'info',
}
const ProtectedRouteContent = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const user = useAppSelector((state) => {
    return state.authentication.user;
  });

  const isNotLoginPage = !matchPath(location.pathname, ['/login']) ||
    (!matchPath(location.pathname, ['/support', '/reset', 'join']) && typeof user !== 'undefined');

  return (
    <>
      <NetworkInterceptor />
      <Switch>
        <ProtectedRoute path={superOptionalOnboardingRoute} exact>
          <ProtectedRoute path={onboardingPath}>
            <Onboarding path={onboardingPath} />
          </ProtectedRoute>
          <AppLayout>
            <HomePage />
          </AppLayout>
        </ProtectedRoute>
        <Route path="/registration" exact>
          <Registration />
        </Route>
        <Route path={'/reset'} exact>
          <PasswordResetPage />
        </Route>

        <Route path={`/reset/:uuid(${matchUuid})`} exact>
          <ConsumePasswordResetPage />
        </Route>
        <Route path="/registration" exact>
          <Registration />
        </Route>
        <Route path={'/login'} exact>
          <Login />
        </Route>
        <ProtectedRoute path={'/introduction'} exact>
          <AppLayout>
            <IntroductionPage />
          </AppLayout>
        </ProtectedRoute>
        <WrappedRoute path={'/client-account'}>
          <AppLayout><ClientAccountPage /></AppLayout>
        </WrappedRoute>
        <ProtectedRoute path={'/user-photo'} exact>
          <AppLayout><UserPhoto /></AppLayout>
        </ProtectedRoute>
        <ProtectedRoute path={'/documents'} exact>
          <AppLayout><DocumentsListPage /></AppLayout>
        </ProtectedRoute>
        <ProtectedRoute path={'/document/upload'} exact>
          <AppLayout><UploadDocumentPage /></AppLayout>
        </ProtectedRoute>
        <Route path={`/join/:uuid(${matchUuid})`} exact>
          <AppLayout><AcceptInvitationPage /></AppLayout>
        </Route>
        <WrappedRoute path={`/projects`} >
          <AppLayout>
            <ProjectList />
          </AppLayout>
        </WrappedRoute>
        <WrappedRoute path={`/project-tools/:uuid(${matchUuid})/reports/incidents/:incident_uuid(${matchUuid})`} exact>
          <AppLayout><IncidentReport title={''} /></AppLayout>
        </WrappedRoute>
        <WrappedRoute path={`/project-tools/:uuid(${matchUuid})/reports/observations/:observation_uuid(${matchUuid})`} exact>
          <AppLayout><ObservationReport /></AppLayout>
        </WrappedRoute>

        <WrappedRoute path={`/project-tools/:uuid(${matchUuid})/reports`} exact>
          <AppLayout><ProjectReportsPage /></AppLayout>
        </WrappedRoute>

        <WrappedRoute path={`/project-tools/:uuid(${matchUuid})`}>
          <AppLayout scroll><ProjectPage /></AppLayout>
        </WrappedRoute>
        <WrappedRoute path={'/project-tools/:uuid(new)'} exact>
          <AppLayout scroll content_id='create_project'><ProjectCreate /></AppLayout>
        </WrappedRoute>
        <WrappedRoute path={'/project-tools'}>
          <AppLayout>
            <ProjectsPage />
          </AppLayout>
        </WrappedRoute>
        <WrappedRoute path={`/economy-tools/:uuid(${matchUuid})`}>
          <AppLayout><EconomyPage /></AppLayout>
        </WrappedRoute>

        <WrappedRoute path={`/economy-tools/(precalculation|precalculation_history|precalculations)`}>
          <AppLayout><EconomyPage /></AppLayout>
        </WrappedRoute>

        <WrappedRoute path={'/economy-tools'} exact>
          <AppLayout>
            <ToolList title='' description='' />
          </AppLayout>
        </WrappedRoute>

        <WrappedRoute path={'/components'} >
          <IonNav root={() => (
            <IonPage>
              <IonHeader>
                <IonToolbar style={{
                  '--min-height': '80px',
                  '--padding-top': '20px',
                  '--padding-bottom': '20px',
                  '--padding-start': '10px',
                }}>
                  <IonButtons slot="start" onClick={() => history.goBack()}>
                    <IonBackButton defaultHref="#"></IonBackButton>
                  </IonButtons>
                  <IonTitle >{t('E2C Components')}</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <Examples />
              </IonContent>
            </IonPage>
          )}
          />
        </WrappedRoute >
        <Route path={`/support/report-error`}>
          <ClickupForm />
        </Route>
        <Route path={`/support`} >
          <Support />
        </Route>
        <WrappedRoute path={`/settings`} >
          <AppLayout content_id={'main'} scroll><UserSettings /></AppLayout>
        </WrappedRoute>
        <Route path={'*'}>
          <PageNotFound />
        </Route>
      </Switch >
    </>
  );
};

export default ProtectedRouteContent;
