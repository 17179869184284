import { useIonRouter } from '@ionic/react';
import React from 'react';

import DesktopWrapper from '../../../components/DesktopWrapper';
import i18n from '../../../i18n';
import AppLayout from '../../../layout/AppLayout';
import ProjectForm from '../ProjectForm';

const ProjectCreate: React.FC = () => {
  const router = useIonRouter();

  return (
    <AppLayout header={{ hasHeader: false }} headerTitle={i18n.t('Create new project')}>
      <React.Fragment>
        <DesktopWrapper width={'800px'}>
          <ProjectForm newProject onCancel={() => router.goBack()} />
        </DesktopWrapper>
      </React.Fragment >
    </AppLayout>
  );
};

export default ProjectCreate;
