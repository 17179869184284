import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import { reloadProjects } from '../../reducers/project';
import store from '../../store';
import ToolList from '../Tools/ToolList';

const ProjectsPage: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    store.dispatch(reloadProjects(undefined));
  }, []);

  return (
    <React.Fragment>
      <ToolList title={i18n.t('Project Tools')} description={i18n.t('Select a project to manage.')} />
    </React.Fragment>
  );
};

export default ProjectsPage;
