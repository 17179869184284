import { IonRouterOutlet } from '@ionic/react';

import ProtectedRoute from './ProtectedRoute';
import { NetworkInterceptor } from '../components/NetworkInterceptors/NetworkInterceptor';
import { matchUuid } from '../constants/matchUuid';
import AppLayout from '../layout/AppLayout';
import Notice from '../pages/Notice/Notice';
import NoticeFormPage from '../pages/NoticeBoard/Form';
import NoticeBoard from '../pages/NoticeBoard/NoticeBoard';

const NoticeBoardPages: React.FC = () => {
  return (
    <IonRouterOutlet>
      <NetworkInterceptor />
      <ProtectedRoute path={`/notice-board/:uuid(${matchUuid})/:notice_uuid(${matchUuid})?/add`} exact render={({ match }: any) => (
        <AppLayout scroll={true}><NoticeFormPage /></AppLayout>
      )} />
      <ProtectedRoute path={`/notice-board/:uuid(${matchUuid})/notice/:notice_uuid(${matchUuid})`} exact render={({ match }: any) => (
        <AppLayout scroll={true}><Notice /></AppLayout>
      )} />
      <ProtectedRoute path={`/notice-board/notice/:notice_uuid(${matchUuid})`} exact render={({ match }: any) => (
        <AppLayout scroll={true}><Notice /></AppLayout>
      )} />
      <ProtectedRoute path={`/notice-board/:uuid(${matchUuid})`} exact render={({ match }: any) => (
        <AppLayout scroll={true}><NoticeBoard /></AppLayout>
      )} />
      <ProtectedRoute path="/notice-board" exact render={() => (
        <AppLayout scroll={true}><NoticeBoard /></AppLayout>
      )} />
    </IonRouterOutlet>
  );
};

export default NoticeBoardPages;
